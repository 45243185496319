<i18n src="@/i18n/subscriber.json"></i18n>

<template lang="pug">
.subscriber-autologin.view
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {SUBSCRIBER_LOGIN} from "@/store/types"

export default {
  data() {
    return {}
  },
  props: [
    'uid',
    'sender_id',
  ],
  computed: {
    ...mapGetters([
      'subscriber',
    ])
  },
  created() {
    this.login()
  },
  methods: {
    ...mapActions([
      SUBSCRIBER_LOGIN,
    ]),
    login() {
      this.SUBSCRIBER_LOGIN({uid: this.uid})
          .then(() => {
            this.$router.push({name: 'senders.suggestions', params: {sender_id: this.sender_id || null}})
          }).catch((error) => {
        if (error.response.data.errors) {
          this.$root.pushAppMessages(error.response.data.errors.map((message) => {
            this.$router.push({name: 'index'})
          }))
        }

        this.$router.push({name: 'index'})
      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
