import { render, staticRenderFns } from "./AutoLogin.vue?vue&type=template&id=1f86f263&scoped=true&lang=pug&"
import script from "./AutoLogin.vue?vue&type=script&lang=js&"
export * from "./AutoLogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f86f263",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/subscriber.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fopt-in.ru%2Fsrc%2Fviews%2Fsubscriber%2FAutoLogin.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports